import React, { useState } from "react";
import { Form, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

export default function AddCountry(){
    const API_URL = BASE_URL+"/addcountry";
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const country = formData.get("country");
        
        if(!country){
            toast.error("All fields are required!");
        }
        else{
            const data = {
                countryName: country
            }
            axios.post(API_URL, data, {
                headers: {
                    "Authorization": apiKey,
                    "Content-Type": "application/json"
                }
            })
            .then(
                res => {
                    navigate("/countries")
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
        }
    }
    return(
        <div className="container">
            <ToastContainer />
            <h2>Add New Country</h2>
            <br />
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-6">
                        <input type="text" placeholder="Enter Country Name" className="form-control" name="country" required/>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-6">
                        <button className="btn btn-primary">Add Country</button>
                    </div>
                </div>
            </form>
        </div>
    );
}