import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../components/Loader";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY; 

export default function UpdateLead(){
    const {id} = useParams();
    const API_URL = BASE_URL+"/updatelead";
    const navigate = useNavigate();

    //getting countries
    const GET_COUNTRY_URL = BASE_URL+"/getcountries";

    const [countries, setCountry] = useState([]);
    useEffect(() => {
        axios.get(GET_COUNTRY_URL, {
            headers: {
                "Authorization": apiKey,
                "Content-Type": "application/json"
            }
        })
        .then(
            res => {
                setCountry(res.data);
                setLoading(false);
            }
        )
        .catch(
            error => {
                console.log("There was an error", error)
            }
        )
    }, [])

    //getting leads
    const GET_LEAD_URL = BASE_URL+"/getleads";

    const [leads, setLead] = useState([]);
    useEffect(() => {
        axios.get(GET_LEAD_URL, {
            headers: {
                "Authorization": apiKey,
                "Content-Type": "application/json"
            }
        })
        .then(
            res => {
                setLead(res.data);
                setLoading(false);
            }
        )
        .catch(
            error => {
                console.log("There was an error", error)
            }
        )
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const name = formData.get("name");
        const email = formData.get("email");
        const phone = formData.get("phone");
        const business = formData.get("business");
        const country = formData.get("country");
        
        if(!name || !email || !phone || !business || !country){
            toast.error("All fields are required!");
        }
        else{
            const data = {
                clientName: name,
                email: email,
                phoneNumber: phone,
                businessName: business,
                country: country,
                id: id
            }
            axios.put(API_URL, data, {
                headers: {
                    "Authorization": apiKey,
                    "Content-Type": "application/json"
                }
            })
            .then(
                res => {
                    toast.success("Lead updated successfully.");
                    setTimeout(() => {
                        navigate("/allleads");
                      }, 2000);
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
        }
    }
    const [loading, setLoading] = useState(true);
    if (loading) return <Loader />;
    return(
        <div className="container">
            <ToastContainer />
            <h2>Update Lead</h2>
            <br />
            {leads
            .filter(lead => lead.Id == id)
            .map(lead => (
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6">
                            <input type="text" placeholder="Enter Client Name" className="form-control" defaultValue={lead.Name} name="name" required/>
                        </div>
                        <div className="col-sm-6">
                            <input type="email" placeholder="Enter Email Here" className="form-control" defaultValue={lead.Email} name="email" required/>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-sm-6">
                            <input type="text" placeholder="Enter Phone Number" className="form-control" defaultValue={lead.PhoneNumber} name="phone" required/>
                        </div>
                        <div className="col-sm-6">
                            <input type="text" placeholder="Business Name" className="form-control" defaultValue={lead.BusinessName} name="business" required/>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-sm-6">
                            <select className="form-control" name="country" required>
                                <option disabled selected>Select Country</option>
                                {countries
                                .filter(country => country.Status === "Active")
                                .map(country => (
                                    <option value={country.Country} selected={lead.Country == country.Country}>{country.Country}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-sm-6">
                            <button className="btn btn-primary">Update Lead</button>
                        </div>
                    </div>
                </form>
            ))}
        </div>
    )
}