import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import { toast, ToastContainer } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

export default function DeleteLead(){
    const {id} = useParams();
    const [leads, getLead] = useState([]);
    const navigate = useNavigate();
    const API_URL = BASE_URL+"/getleads";

    useEffect(() => {
        axios.get(API_URL, {
            headers: {
                "Authorization": apiKey,
                "Content-Type": "application/json"
            }
        })
        .then(
            res => {
                getLead(res.data);
                setLoading(false);
            }
        )
        .catch(
            error => {
                console.log("There was an error", error);
            }
        )
    }, [])

    const deleteLead = (e) => {
        e.preventDefault();
        const DELETE_URL = BASE_URL+`/deletelead/${id}`;

        const formData = new FormData(e.target);
        const getCountry = formData.get("setCountry")
        axios.delete(DELETE_URL, {
            headers: {
                "Authorization": apiKey,
                "Content-Type": "application/json"
            }
        })
        .then(
            res => {
                toast.success("Lead deleted successfully.");
                setTimeout(() => {
                    navigate("/leads/"+getCountry); // Change "/new-page" to your target route
                    }, 2000);
            }
        )
        .catch(
            error => {
                console.log("There was an error", error);
            }
        )
    }
    const [loading, setLoading] = useState(true);
    if (loading) return <Loader />;
    return(
        <div className="container">
            <ToastContainer />
            <br /><br />
            <form onSubmit={deleteLead}>
                {leads.filter(lead => lead.Id == id)
                .map(lead => (
                    <>
                        <h2>Are you sure you want to delete this lead with client name <i>"{lead.Name}"</i> ?</h2>
                        <input type="hidden" value={lead.Country} name="setCountry"/>
                    </>
                ))}
                <br />
                <button className="btn btn-danger">Yes Delete</button>
            </form>
        </div>
    )
}