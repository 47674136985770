import React, { useEffect, useState } from "react";
import { Form, Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../components/Loader";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

export default function UpdateCountry(){
    const {id} = useParams();
    const UPDATE_URL = BASE_URL+"/updatecountry";
    const GET_URL = BASE_URL+"/getcountries";
    const navigate = useNavigate();
    const [countries, setCountry] = useState([]);

    useEffect(() =>{
    axios.get(GET_URL, {
        headers: {
            "Authorization": apiKey,
            "Content-Type": "application/json"
        }
    })
    .then(
        res => {
            setCountry(res.data);
            setLoading(false);
        }
    )
    .catch(
        error => {
            console.log("There was an error", error)
        }
    )}, [])
    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const country = formData.get("country");
        const status = formData.get("status");
        
        if(!country){
            toast.error("All fields are required!");
        }
        else{
            const data = {
                countryName: country,
                status: status,
                id: id
            }
            axios.put(UPDATE_URL, data, {
                headers: {
                    "Authorization": apiKey,
                    "Content-Type": "application/json"
                }
            })
            .then(
                res => {
                    navigate("/countries")
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
        }
    }
    const [loading, setLoading] = useState(true);
    if (loading) return <Loader />;
    return(
        <div className="container">
            <ToastContainer />
            <h2>Update Country</h2>
            <br />
            {countries
            .filter(country => country.Id == id)
            .map(country => (
            <form onSubmit={handleSubmit}>
                
                <div className="row">
                    <div className="col-sm-6">
                        <input type="text" placeholder="Enter Country Name" className="form-control" defaultValue={country.Country} name="country" required/>
                    </div>
                    <div className="col-sm-6">
                        <select className="form-control" name="status">
                            <option disabled>Select Status</option>
                            <option value="Active" selected={country.Status === "Active"}>Active</option>
                            <option value="Deactive" selected={country.Status === "Deactive"}>Deactive</option>
                        </select>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-6">
                        <button className="btn btn-primary">Update</button>
                    </div>
                </div>
            </form>
            ))
        }
        </div>
    );
}