import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

export default function ShowLeads() {
    const getRole = Cookies.get("user_role");
    const API_URL = `${BASE_URL}/getleads`;
    const STATUS_URL = `${BASE_URL}/getcountries`;
    const { country } = useParams();

    const [leads, setLeads] = useState([]);
    const [status, setStatus] = useState([]);
    const [query, setQuery] = useState("");
    const [filteredLeads, setFilteredLeads] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const itemsPerPage = 50;

    // Fetch Status Data
    useEffect(() => {
        axios.get(STATUS_URL, {
            headers: {
                "Authorization": apiKey,
                "Content-Type": "application/json"
            }
        })
        .then(res => {
            setStatus(res.data);
        })
        .catch(error => {
            console.log("Error fetching status:", error);
        });
    }, [apiKey]);

    // Fetch Leads Data
    useEffect(() => {
        axios.get(API_URL, {
            headers: {
                "Authorization": apiKey,
                "Content-Type": "application/json"
            }
        })
        .then(res => {
            setLeads(res.data);
            setFilteredLeads(res.data);
            setLoading(false);
        })
        .catch(error => {
            console.log("Error fetching leads:", error);
        });
    }, [apiKey, country]);

    // Filter Leads Based on Search Query
    useEffect(() => {
        if (query.length >= 4) {
            const filtered = leads.filter(lead =>
                Object.values(lead).some(value =>
                    String(value).toLowerCase().includes(query.toLowerCase())
                )
            );
            setFilteredLeads(filtered);
        } else {
            setFilteredLeads(""); // Reset when query is less than 3 characters
        }
    }, [query, leads]);    

    // Pagination Calculation
    const totalPages = Math.ceil(filteredLeads.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentLeads = filteredLeads.slice(indexOfFirstItem, indexOfLastItem);


    //disable right click and developer tools
    useEffect(() => {
        // Disable Right Click
        document.addEventListener("contextmenu", (e) => e.preventDefault());

        // Disable Developer Tools & Copy Shortcuts
        document.addEventListener("keydown", (e) => {
            if (
                (e.ctrlKey &&
                    (e.key === "u" || e.key === "c" || e.key === "x" || e.key === "s" || e.key === "i" || e.key === "j" || e.key === "k")) ||
                (e.ctrlKey && e.shiftKey && (e.key === "I" || e.key === "J" || e.key === "C" || e.key === "S")) // Block Ctrl+Shift+I/J/C
            ) {
                e.preventDefault();
            }

            // Block PrintScreen Key
            if (e.key === "PrintScreen") {
                e.preventDefault();
                blankScreenEffect();
            }
        });

        // Detect Windows Snipping Tool (Windows + Shift + S) More Accurately
        const detectSnippingTool = (event) => {
            if (event.metaKey || (event.shiftKey && event.key === "S")) {
                blankScreenEffect();
            }
        };

        document.addEventListener("keydown", detectSnippingTool);

        // Function to Temporarily Hide Content on Screenshot Attempt
        const blankScreenEffect = () => {
            const overlay = document.createElement("div");
            overlay.id = "screenshot-blocker";
            overlay.style.position = "fixed";
            overlay.style.top = "0";
            overlay.style.left = "0";
            overlay.style.width = "100vw";
            overlay.style.height = "100vh";
            overlay.style.background = "white"; // Makes screenshot blank
            overlay.style.zIndex = "9999";
            overlay.style.pointerEvents = "none";
            overlay.style.opacity = "1";
            document.body.appendChild(overlay);

            // Remove overlay after 2 seconds
            setTimeout(() => {
                const existingOverlay = document.getElementById("screenshot-blocker");
                if (existingOverlay) {
                    document.body.removeChild(existingOverlay);
                }
            }, 1000);
        };

        return () => {
            document.removeEventListener("contextmenu", (e) => e.preventDefault());
            document.removeEventListener("keydown", detectSnippingTool);
        };
    }, []);
      
    if (loading) return <Loader />;

    return (
        <div className="container">
            <h2>Showing {country} Leads</h2>
            <br />
            <div className="row">
                <div className="col-sm-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </div>
            </div>
            <br /><br />
            <table className="table table-striped table-dark" style={{userSelect: "none"}}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Business Name</th>
                        {getRole === "Administrator" && (
                            <>
                                <th>Create At</th>
                                <th>Update</th>
                                <th>Delete</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {query && currentLeads.length > 0 ? (
                        status
                            .filter(sta => sta.Status === "Active" && sta.Country === country)
                            .length > 0 &&
                        currentLeads
                            .filter(lead => lead.Country === country)
                            .map(lead => (
                                <tr key={lead.Id}>
                                    <td>{lead.Name}</td>
                                    <td>{lead.Email}</td>
                                    <td>{lead.PhoneNumber}</td>
                                    <td>{lead.BusinessName}</td>
                                    {getRole === "Administrator" && (
                                        <>
                                            <td>{lead.Created_At}</td>
                                            <td>
                                                <Link to={`/updatelead/${lead.Id}`}>
                                                    <i className="bi bi-pencil-square text-dark"></i>
                                                </Link>
                                            </td>
                                            <td>
                                                <Link to={`/deletelead/${lead.Id}`}>
                                                    <i className="bi bi-trash3 text-danger"></i>
                                                </Link>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ))
                    ) : (
                        <tr>
                            <td colSpan={getRole === "Administrator" ? 7 : 4} className="text-center">
                                No matching records found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Pagination */}
            {totalPages > 1 && (
                <nav>
                    <ul className="pagination" style={{display: "flex", flexWrap: "wrap"}}>
                        {[...Array(totalPages)].map((_, i) => (
                            <li key={i} className={`page-item ${i + 1 === currentPage ? "active" : ""}`}>
                                <button className="page-link" onClick={() => setCurrentPage(i + 1)}>
                                    {i + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            )}
        </div>
    );
}