import React from "react";

export default function Loader(){
    return(
        <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
            <span class="sr-only"></span>
        </div>
        </div>
    )
}