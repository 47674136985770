import React, { useState } from "react";
import axios from "axios";

const UploadEmailCSV = () => {
  const [file, setFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a CSV file.");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await axios.post("https://api.exceptronsolutions.com/upload-emails", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setResponse(res.data);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCSV = () => {
    if (!response || !response.processedData) {
      alert("No data available to download.");
      return;
    }

    const validEmails = response.processedData.filter((email) => email.status === "Valid");

    if (validEmails.length === 0) {
      alert("No valid emails to download.");
      return;
    }

    const csvContent =
      "data:text/csv;charset=utf-8," +
      ["email,clientName,businessName"]
        .concat(validEmails.map((email) => `${email.email},${email.clientName},${email.businessName}`))
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "valid_emails.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="container">
      <h2 className="text-light">Upload CSV File</h2>
      <p className="text-light"><i>Note: CSV have maximum 2000 rows and column sequence like this <b>"email | clientName | businessName"</b> with same alphabets.</i></p>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button className="btn btn-primary" onClick={handleUpload} disabled={loading}>
        {loading ? "Processing..." : "Upload"}
      </button>
      {response && (
        <div>
          <hr />
          <h3>Results</h3>
          <p>Total Emails: {response.total}</p>
          <p>Valid Emails: {response.valid}</p>
          <p>Invalid Emails: {response.invalid}</p>

          <div className="row">
            <div className="col-sm-8">
              <h4>Processed Data</h4>
            </div>
            <div className="col-sm-4">
              <button className="btn btn-primary" onClick={handleDownloadCSV}>Download Valid Emails</button>
            </div>
          </div>
          <br />
          <table border="1" className="table-dark">
            <thead>
              <tr>
                <th>Email</th>
                <th>Domain</th>
                <th>Client Name</th>
                <th>Business Name</th>
                <th>Status</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {response.processedData.map((email, index) => (
                <tr key={index}>
                  <td>{email.email}</td>
                  <td>{email.domain}</td>
                  <td>{email.clientName}</td>
                  <td>{email.businessName}</td>
                  <td>{email.status}</td>
                  <td>{email.reason}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default UploadEmailCSV;
