import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import axios from "axios";
import Cookies from "js-cookie";
import {toast, ToastContainer} from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

export default function AllLeads() {
    const getRole = Cookies.get("user_role");
    const API_URL = `${BASE_URL}/getleads`;
    const navigate = useNavigate();

    const [leads, setLeads] = useState([]);
    const [filteredLeads, setFilteredLeads] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;

    // Fetch leads on mount
    useEffect(() => {
        axios.get(API_URL, {
            headers: {
                Authorization: API_KEY,
                "Content-Type": "application/json",
            },
        })
        .then((res) => {
            setLeads(res.data);
            setFilteredLeads(res.data);
            setLoading(false);
        })
        .catch((error) => {
            console.error("Error fetching leads:", error);
            setLoading(false);
        });
    }, []);

    // Search functionality
    useEffect(() => {
        const filtered = leads.filter((lead) =>
            Object.values(lead).some(
                (val) => val.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setFilteredLeads(filtered);
        setCurrentPage(1); // Reset to first page when searching
    }, [searchTerm, leads]);

    // select all code
    const selectAll = () => {
        const selAll = document.getElementById('selectAll');
        const rows = document.querySelectorAll('#Table tbody tr');

        rows.forEach(row => {
            if (row.style.display !== 'none') {
                const checkbox = row.querySelector('input[name="ids[]"]');
                if (checkbox) {
                    checkbox.checked = selAll.checked; // Select or deselect based on the "Select All" checkbox
                }
            }
        })
        
    }

    // Pagination
    const totalPages = Math.ceil(filteredLeads.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentLeads = filteredLeads.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (page) => setCurrentPage(page);

    // Bulk Edit / Delete
    const bulkEdit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const status = formData.get("action");
        const ids = formData.getAll("ids[]");

        if (status === "Delete" && ids.length === 0) {
            alert("Please select at least one lead to delete.");
            return;
        }

        if (status === "Delete") {
            try {
                await Promise.all(
                    ids.map((id) =>
                        axios.delete(`${BASE_URL}/deletelead/${id}`, {
                            headers: { Authorization: API_KEY },
                        })
                    )
                );
                setLeads(leads.filter((lead) => !ids.includes(lead.Id))); // Update state
                // alert("Selected leads deleted successfully.");
                toast.success("Selected leads deleted successfully.");
                setTimeout(() => {
                    navigate(0); // Change "/new-page" to your target route
                }, 2000);
            } catch (error) {
                console.error("Error deleting leads:", error);
            }
        }
    };

    const getids = [];
    const deleteAll = async () => {
        const confirmation = window.confirm("Are you sure you want to delete all data?");
        if (!confirmation) return;
    
        setLoading(true);
    
        leads.map(lead => (
            getids.push(lead.Id)
        ));
        const batchSize = 100; // Adjust batch size as needed
    
        try {
            for (let i = 0; i < getids.length; i += batchSize) {
                const batch = getids.slice(i, i + batchSize); // Get batch of IDs
                await Promise.all(
                    batch.map((id) =>
                        axios.delete(`${BASE_URL}/deletelead/${id}`, {
                            headers: { Authorization: API_KEY },
                        })
                    )
                );
            }
    
            toast.success("All leads have been deleted successfully.");
            setTimeout(() => {
                navigate(0);
            }, 2000);
        } catch (error) {
            console.error("Error deleting leads:", error);
        } finally {
            setLoading(false);
        }
    }

    // delete specific data
    const deleteSpecificData = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const getDate = formData.get("dates");

        // console.log(getDate);
    
        setLoading(true);
        leads
        .filter(lead => lead.Created_At == getDate)
        .map(lead => (
            getids.push(lead.Id)
        ));
        const batchSize = 100; // Adjust batch size as needed
    
        try {
            for (let i = 0; i < getids.length; i += batchSize) {
                const batch = getids.slice(i, i + batchSize); // Get batch of IDs
                await Promise.all(
                    batch.map((id) =>
                        axios.delete(`${BASE_URL}/deletelead/${id}`, {
                            headers: { Authorization: API_KEY },
                        })
                    )
                );
            }
    
            toast.success("All leads have been deleted successfully.");
            setTimeout(() => {
                navigate(0);
            }, 2000);
        } catch (error) {
            console.error("Error deleting leads:", error);
        } finally {
            setLoading(false);
        }
    }

    const [loading, setLoading] = useState(true);
    if (loading) return <Loader />;

    return (
        <div className="container">
            <ToastContainer />
            <form onSubmit={bulkEdit}>
                <h2>Showing All Leads</h2>
                <div className="row">
                    <div className="col-sm-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search here..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="col-sm-3">
                        <select name="action" className="p-1 m-1" style={{ width: "150px" }}>
                            <option value="Active">Active</option>
                            <option value="Deactive">Deactive</option>
                            <option value="Delete">Delete</option>
                        </select>
                        <button className="btn btn-primary">Apply</button>
                    </div>
                    <div className="col-sm-2">
                        <button className="btn btn-danger" onClick={deleteAll}>Delete All Data</button>
                    </div>
                    <div className="col-sm-3">
                        <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#myModal">
                            Delete Specific Data
                        </button>
                    </div>
                </div>
                <br />
                <table className="table table-striped table-dark" id="Table">
                    <thead>
                        <tr>
                            <th scope="col"><input type="checkbox" id="selectAll" onChange={selectAll}/></th>
                            <th>Country</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Business Name</th>
                            {getRole === "Administrator" && (
                                <>
                                    <th>Created At</th>
                                    <th>Update</th>
                                    <th>Delete</th>
                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {currentLeads.length > 0 ? (
                            currentLeads.map((lead) => (
                                <tr key={lead.Id}>
                                    <td>
                                        <input type="checkbox" value={lead.Id} name="ids[]" />
                                    </td>
                                    <td>{lead.Country}</td>
                                    <td>{lead.Name}</td>
                                    <td>{lead.Email}</td>
                                    <td>{lead.PhoneNumber}</td>
                                    <td>{lead.BusinessName}</td>
                                    {getRole === "Administrator" && (
                                        <>
                                            <td>{lead.Created_At}</td>
                                            <td>
                                                <Link to={`/updatelead/${lead.Id}`}>
                                                    <i className="bi bi-pencil-square text-light"></i>
                                                </Link>
                                            </td>
                                            <td>
                                                <Link to={`/deletelead/${lead.Id}`}>
                                                    <i className="bi bi-trash3 text-danger"></i>
                                                </Link>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="9" className="text-center">
                                    No records available to show.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </form>

            {/* Pagination */}
            {totalPages > 1 && (
                <nav>
                    <ul className="pagination" style={{display: "flex", flexWrap: "wrap"}}>
                        {[...Array(totalPages)].map((_, i) => (
                            <li key={i} className={`page-item ${i + 1 === currentPage ? "active" : ""}`}>
                                <button className="page-link" onClick={() => setCurrentPage(i + 1)}>
                                    {i + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            )}

            {/* modal box */}
            <div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Delete Data By Date</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={deleteSpecificData}>
                        <div class="modal-body">
                            <strong>Select Date</strong><br />
                                <select name="dates">
                                {
                                    [...new Set(leads.map((lead) => lead.Created_At))].map((uniqueDate) => (
                                        <option key={uniqueDate}>{uniqueDate}</option>
                                    ))
                                }
                                </select>
                            
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    );
}