import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

export default function FollowUp() {
    const getRole = Cookies.get("user_role");
    const getUsername = Cookies.get("username");
    const API_URL = `${BASE_URL}/getfollowups`;

    const [leads, setFollowUps] = useState([]);
    const [filteredLeads, setFilteredLeads] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 50;

    // Fetch Leads Data
    useEffect(() => {
        axios.get(API_URL, {
            headers: {
                "Authorization": apiKey,
                "Content-Type": "application/json"
            }
        })
        .then(res => {
            setFollowUps(res.data);
            setFilteredLeads(res.data);
            setLoading(false);
        })
        .catch(error => {
            console.log("Error fetching leads:", error);
        });
    }, [apiKey]);

    // search table code..
    const searchTable = (e) => {
        const input = document.getElementById("search");
        const filter = input.value.toLowerCase();
        const table = document.getElementById("Table");
        const rows = table.getElementsByTagName("tr");
    
        for (let i = 1; i < rows.length; i++) { // Start at 1 to skip the header row
            const cells = rows[i].getElementsByTagName("td");
            let match = false;
    
            for (let j = 0; j < cells.length; j++) {
                if (cells[j]) {
                    const cellValue = cells[j].textContent || cells[j].innerText;
                    if (cellValue.toLowerCase().indexOf(filter) > -1) {
                        match = true;
                        break;
                    }
                }
            }
    
            rows[i].style.display = match ? "" : "none";
        }
    }    

    // Pagination Calculation
    const totalPages = Math.ceil(filteredLeads.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentLeads = filteredLeads.slice(indexOfFirstItem, indexOfLastItem);
      
    const [loading, setLoading] = useState(true);

    if (loading) return <Loader />;

    return (
        <div className="container">
            {getRole === "User" ? (
                <h2>Showing Your Follow Ups</h2>
            ):
            (
                <h2>Showing All Follow Ups</h2>
            )}
            <br />
            <div className="row">
                {getRole === "User" && (
                    <div className="col-sm-2">
                        <Link to="/add-follow-up"><button className="btn btn-primary">Add Follow Up</button></Link>
                    </div>
                )}
                <div className="col-sm-3">
                    <input type="text" class="form-control" id="search" placeholder="Search here..." onKeyUp={searchTable} />
                </div>
            </div>
            <br /><br />
            <table className="table table-striped table-dark" id="Table" style={getRole == "User" ? {userSelect: "none"}: undefined}>
                <thead>
                    <tr>
                        {getRole === "Administrator" && (
                            <>
                            <th>Employee Name</th>
                            </>
                        )}
                        <th>Client Name</th>
                        <th>Client Email</th>
                        <th>Phone</th>
                        <th>Business Name</th>
                        {getRole === "Administrator" ? (
                            <>
                            <th>Employee Email</th>
                            </>
                        ):
                        (
                            <>
                            <th>Your Email</th>
                            </>
                        )
                        }
                        <th>Client Reply</th>
                        <th>Create At</th>
                        {getRole === "User" && (
                            <th>Update</th>
                        )}
                        {getRole === "Administrator" && (
                            <>
                                <th>Delete</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {getRole === "User" &&
                        leads
                        .filter(lead => lead.EmployeeName == getUsername)
                        .map(lead => (
                            <tr key={lead.Id}>
                                <td>{lead.ClientName}</td>
                                <td>{lead.ClientEmail}</td>
                                <td>{lead.PhoneNumber}</td>
                                <td>{lead.BusinessName}</td>
                                <td>{lead.EmployeeEmail?.split(" ").slice(0, 5).join(" ")}</td>
                                <td>{lead.ClientReply?.split(" ").slice(0, 5).join(" ")}</td>
                                <td>{lead.Created_At}</td>
                                {getRole === "User" && (
                                    <td>
                                        <Link to={`/update-follow-up/${lead.Id}`}>
                                            <i className="bi bi-pencil-square text-light"></i>
                                        </Link>
                                    </td>
                                )}
                            </tr>
                        ))}
                    {getRole === "Administrator" &&
                        leads
                        .map(lead => (
                            <tr key={lead.Id}>
                                {getRole === "Administrator" && (
                                    <>
                                    <td><Link to={`/update-follow-up/`+lead.Id}>{lead.EmployeeName}</Link></td>
                                    </>
                                )}
                                <td>{lead.ClientName}</td>
                                <td>{lead.ClientEmail}</td>
                                <td>{lead.PhoneNumber}</td>
                                <td>{lead.BusinessName}</td>
                                <td>{lead.EmployeeEmail?.split(" ").slice(0, 5).join(" ")}</td>
                                <td>{lead.ClientReply?.split(" ").slice(0, 5).join(" ")}</td>
                                <td>{lead.Created_At}</td>
                                {getRole === "Administrator" && (
                                    <>
                                        <td>
                                            <Link to={`/delete-follow-up/${lead.Id}`}>
                                                <i className="bi bi-trash3 text-danger"></i>
                                            </Link>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))}
                        {leads.length == 0 &&
                            <tr>
                                <td colSpan="9" className="text-center">No records available to show.</td>
                            </tr>
                        }
                </tbody>
            </table>

            {/* Pagination */}
            {totalPages > 1 && (
                <nav>
                    <ul className="pagination" style={{display: "flex", flexWrap: "wrap"}}>
                        {[...Array(totalPages)].map((_, i) => (
                            <li key={i} className={`page-item ${i + 1 === currentPage ? "active" : ""}`}>
                                <button className="page-link" onClick={() => setCurrentPage(i + 1)}>
                                    {i + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            )}
        </div>
    );
}