import React, { useState } from "react";
import App from "../App";
import Cookies from "js-cookie";
import { BrowserRouter, Link, useNavigate } from "react-router-dom";
import Login from "../pages/Login";
import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;


export default function Sidebar(){
    const getUsername = Cookies.get("username");
    const getRole = Cookies.get("user_role");
    const navigate = useNavigate();
    const LOGOUT_URL = BASE_URL+"/logout";
    const logout = () => {
        axios.post(LOGOUT_URL, [],{
            headers:{
                "Authorization": apiKey,
                "Content-Type": "application/json"
            }
        }).
        then(
            res => {
                Cookies.remove("username");
                navigate("/");
            }
            
        )
        .catch(
            error => {
                console.error(error);
            }
        )
    }

    const API_URL = BASE_URL+"/getcountries";

    const [countries, setCountry] = useState([]);
    axios.get(API_URL, {
        headers: {
            "Authorization": apiKey,
            "Content-Type": "application/json"
        }
    })
    .then(
        res => {
            setCountry(res.data);
        }
    )
    .catch(
        error => {
            console.log("There was an error", error)
        }
    )
    return(
        <div>            
            {getUsername ?( 
            <div class="container-fluid">
                <div class="row flex-nowrap">
                    
                    <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
                        <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                            <Link to="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                                <span class="fs-5 d-none d-sm-inline">CRM Portal</span>
                            </Link>
                            <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                                <li class="nav-item">
                                    <Link to="/" class="nav-link align-middle px-0 text-light">
                                        <i class="fs-4 bi-house"></i> <span class="ms-1 d-none d-sm-inline">Home</span>
                                    </Link>
                                </li>
                                <li>
                                    <a href="#submenu1" data-bs-toggle="collapse" class="nav-link px-0 align-middle text-light">
                                        <i class="bi bi-bar-chart"></i> <span class="ms-1 d-none d-sm-inline">Leads</span> </a>
                                    <ul class="collapse show nav flex-column ms-1" id="submenu1" data-bs-parent="#menu">
                                        {countries
                                        .filter(country => country.Status === "Active")
                                        .map(country => (
                                            <li class="w-100">
                                                <Link to={"leads/"+country.Country} class="nav-link px-0 text-light"> <span class="d-none d-sm-inline">{country.Country}</span></Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <Link to="/follow-up-sheet" class="nav-link align-middle px-0 text-light">
                                        <i class="fs-6 bi-clipboard"></i> <span class="ms-1 d-none d-sm-inline">Follow Up Sheet</span>
                                    </Link>
                                </li>
                                {getRole == "Administrator" &&
                                    <>
                                        <li>
                                            <Link to="uploadleads" class="nav-link px-0 align-middle text-light">
                                                <i class="bi bi-cloud-arrow-up"></i> <span class="ms-1 d-none d-sm-inline">Upload Leads</span></Link>
                                        </li>

                                        <li>
                                            <Link to="countries" class="nav-link px-0 align-middle text-light">
                                                <i class="bi bi-map"></i> <span class="ms-1 d-none d-sm-inline">Countries</span></Link>
                                        </li>

                                        <li>
                                            <Link to="allleads" class="nav-link px-0 align-middle text-light">
                                                <i class="bi bi-clipboard-data"></i> <span class="ms-1 d-none d-sm-inline">All Data</span></Link>
                                        </li>
                                    
                                        <li>
                                            <Link to="/users" class="nav-link px-0 align-middle text-light">
                                                <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline">Users</span> </Link>
                                        </li>

                                        <li>
                                            <Link to="/emails" class="nav-link px-0 align-middle text-light">
                                                <i class="fs-4 bi-envelope"></i> <span class="ms-1 d-none d-sm-inline">Emails</span> </Link>
                                        </li>
                                    </>
                                }    
                            </ul>
                            <hr />
                            <div class="dropdown pb-4">
                                <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvts5aHBstDkR8PigS4RmZkbZy78zpZoSuOw&s" alt="hugenerd" width="30" height="30" class="rounded-circle" />
                                    <span class="d-none d-sm-inline mx-1">{getUsername}</span>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
                                    <li><Link to="updateprofile" class="dropdown-item">Profile</Link></li>
                                    <li>
                                        <hr class="dropdown-divider" />
                                    </li>
                                    <li><Link class="dropdown-item" to="/" onClick={logout}>Sign out</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col py-3 bg-dark">
                        <App />
                    </div>
                </div>
            </div>    
            ):
            (
                <Login />
            )
            }
        </div>
    )
}