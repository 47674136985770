import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

export default function Emails(){
    const getRole = Cookies.get("user_role");
    const getUsername = Cookies.get("username");
    return(
        <div className="container">
            <h2>Welcome, "{getUsername}"</h2>
            <br />
            <div className="row">
                {getRole == "Administrator" && 
                <div class="card" style={{width: "20rem", margin: "10px"}}>
                    <div class="card-body">
                        <h5 class="card-title">Email Validation</h5>
                        <h4 class="card-subtitle mb-2 text-success"></h4>
                        <Link to="validate-emails" class="card-link">Validate Emails</Link>
                    </div>
                </div>
                }
                {getRole == "Administrator" && 
                <div class="card" style={{width: "20rem", margin: "10px"}}>
                    <div class="card-body">
                        <h5 class="card-title">Email Sender</h5>
                        <h4 class="card-subtitle mb-2 text-success"></h4>
                        <Link to="email-sender" class="card-link">Send Emails</Link>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}