import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import Cookies from "js-cookie";
import Loader from "../components/Loader";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY; 

export default function UpdateFollowUp(){
    const {id} = useParams();
    const getUsername = Cookies.get("username");
    const getRole = Cookies.get("user_role");
    const API_URL = BASE_URL+"/updatefollowup";
    const navigate = useNavigate();

    //getting leads
    const GET_LEAD_URL = BASE_URL+"/getfollowups";

    const [leads, setFollowUp] = useState([]);
    useEffect(() => {
        axios.get(GET_LEAD_URL, {
            headers: {
                "Authorization": apiKey,
                "Content-Type": "application/json"
            }
        })
        .then(
            res => {
                setFollowUp(res.data);
                setLoading(false);
            }
        )
        .catch(
            error => {
                console.log("There was an error", error)
            }
        )
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const clientname = formData.get("cname");
        const clientemail = formData.get("cemail");
        const clientphoneNumber = formData.get("cphone");
        const clientbusiness = formData.get("cbusiness");
        const employee_email = formData.get("employee_email");
        const clientreply = formData.get("creply");
        
        if(!clientname || !clientemail || !clientphoneNumber || !clientbusiness || !employee_email || !clientreply){
            toast.error("All fields are required!");
        }
        else{
            const data = {
                employeeName: getUsername,
                clientName: clientname,
                clientEmail: clientemail,
                phoneNumber: clientphoneNumber,
                businessName: clientbusiness,
                employeeEmail: employee_email,
                clientReply: clientreply,
                id: id
            }
            axios.put(API_URL, data, {
                headers: {
                    "Authorization": apiKey,
                    "Content-Type": "application/json"
                }
            })
            .then(
                res => {
                    toast.success("Follow up updated successfully.");
                    setTimeout(() => {
                        navigate("/follow-up-sheet");
                      }, 2000);
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
        }
    }
    const [loading, setLoading] = useState(true);
    if (loading) return <Loader />;
    return(
        <div className="container">
            <ToastContainer />
            {getRole === "Administrator" ? (
                <h2>See Follow Up</h2>
            ):
            (
                <h2>Update Follow Up</h2>
            )}
            <br />
            {leads
            .filter(lead => lead.Id == id)
            .map(lead => (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-6">
                        {getRole == "Administrator" && (
                            <label>Client Name</label>
                        )}
                        <input type="text" placeholder="Enter Client Name" className="form-control" defaultValue={lead.ClientName} name="cname" required disabled={getRole === "Administrator"}/>
                    </div>
                    <div className="col-sm-6">
                        {getRole == "Administrator" && (
                            <label>Client Email</label>
                        )}
                        <input type="email" placeholder="Enter Client Email" className="form-control" defaultValue={lead.ClientEmail} name="cemail" required disabled={getRole === "Administrator"}/>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-6">
                        {getRole == "Administrator" && (
                            <label>Client Number</label>
                        )}
                        <input type="number" placeholder="Client Phone Number" className="form-control" defaultValue={lead.PhoneNumber} name="cphone" required disabled={getRole === "Administrator"}/>
                    </div>
                    <div className="col-sm-6">
                        {getRole == "Administrator" && (
                            <label>Client Business Name</label>
                        )}
                        <input type="text" placeholder="Client Business Name" className="form-control" defaultValue={lead.BusinessName} name="cbusiness" required disabled={getRole === "Administrator"}/>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-6">
                        {getRole == "Administrator" && (
                            <label>Employee Email</label>
                        )}
                       <textarea className="form-control" placeholder="Type Your Email Here..." required disabled={getRole === "Administrator"} defaultValue={lead.EmployeeEmail} name="employee_email" style={{height: "300px"}}>

                       </textarea>
                    </div>
                    <div className="col-sm-6">
                        {getRole == "Administrator" && (
                            <label>Client Reply Email</label>
                        )}
                       <textarea className="form-control" placeholder="Your Client Reply Here..." required disabled={getRole === "Administrator"} defaultValue={lead.ClientReply} name="creply" style={{height: "300px"}}>

                       </textarea>
                    </div>
                </div>
                <br />
                <div className="row" style={getRole == "Administrator" ? {display: "none"} : null}>
                    <div className="col-sm-6">
                        <button className="btn btn-primary">Update Lead</button>
                    </div>
                </div>
            </form>
            ))}
        </div>
    )
}