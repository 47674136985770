import React, { useState } from "react";
import axios from "axios";
const apiKey = process.env.REACT_APP_API_KEY; 


const UploadCSV = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a CSV file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("https://api.exceptronsolutions.com/upload-csv", formData, {
        headers: { "Authorization": apiKey, "Content-Type": "multipart/form-data" },
      });

      alert(response.data.message);
    } catch (error) {
      console.error("Error uploading CSV:", error);
      alert("Upload failed!");
    }
  };

  return (
    <div>
      <h2>Upload CSV File</h2>
      <p><i>Note: CSV only have these column sequence <b>"clientName | email | phoneNumber | businessName | country"</b> with same alphabets.</i></p>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button className="btn btn-primary" onClick={handleUpload}>Upload to Database</button>
    </div>
  );
};

export default UploadCSV;
