import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import Cookies from "js-cookie";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY; 

export default function AddFollowUp(){
    const getUsername = Cookies.get("username");
    const API_URL = BASE_URL+"/addfollowup";
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const clientname = formData.get("cname");
        const clientemail = formData.get("cemail");
        const clientphoneNumber = formData.get("cphone");
        const clientbusiness = formData.get("cbusiness");
        const employee_email = formData.get("employee_email");
        const clientreply = formData.get("creply");
        
        if(!clientname || !clientemail || !clientphoneNumber || !clientbusiness || !employee_email || !clientreply){
            toast.error("All fields are required!");
        }
        else{
            const data = {
                employeeName: getUsername,
                clientName: clientname,
                clientEmail: clientemail,
                phoneNumber: clientphoneNumber,
                businessName: clientbusiness,
                employeeEmail: employee_email,
                clientReply: clientreply
            }
            axios.post(API_URL, data, {
                headers: {
                    "Authorization": apiKey,
                    "Content-Type": "application/json"
                }
            })
            .then(
                res => {
                    toast.success("Follow up added successfully.");
                    setTimeout(() => {
                        navigate("/follow-up-sheet");
                      }, 2000);
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
        }
    }
    return(
        <div className="container">
            <ToastContainer />
            <h2>Add Follow Up</h2>
            <br />
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-6">
                        <input type="text" placeholder="Enter Client Name" className="form-control" name="cname" required/>
                    </div>
                    <div className="col-sm-6">
                        <input type="email" placeholder="Enter Client Email" className="form-control" name="cemail" required/>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-6">
                        <input type="number" placeholder="Client Phone Number" className="form-control" name="cphone" required/>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" placeholder="Client Business Name" className="form-control" name="cbusiness" required/>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-6">
                       <textarea className="form-control" placeholder="Type Your Email Here..." required name="employee_email" style={{height: "300px"}}>

                       </textarea>
                    </div>
                    <div className="col-sm-6">
                       <textarea className="form-control" placeholder="Your Client Reply Here..." required name="creply" style={{height: "300px"}}>

                       </textarea>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-6">
                        <button className="btn btn-primary">Add Lead</button>
                    </div>
                </div>
            </form>
        </div>
    )
}