import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../components/Loader";
import UploadCSV from "../components/UploadCSV";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY; 

export default function UploadLeads(){
    const API_URL = BASE_URL+"/addlead";
    const navigate = useNavigate();

    const GET_COUNTRY_URL = BASE_URL+"/getcountries";

    const [countries, setCountry] = useState([]);

    useEffect(() => {
        axios.get(GET_COUNTRY_URL, {
            headers: {
                "Authorization": apiKey,
                "Content-Type": "application/json"
            }
        })
        .then(
            res => {
                setCountry(res.data);
                setLoading(false);
            }
        )
        .catch(
            error => {
                console.log("There was an error", error)
            }
        )
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const name = formData.get("name");
        const email = formData.get("email");
        const phone = formData.get("phone");
        const business = formData.get("business");
        const country = formData.get("country");
        
        if(!name || !email || !phone || !business || !country){
            toast.error("All fields are required!");
        }
        else{
            const data = {
                clientName: name,
                email: email,
                phoneNumber: phone,
                businessName: business,
                country: country
            }
            axios.post(API_URL, data, {
                headers: {
                    "Authorization": apiKey,
                    "Content-Type": "application/json"
                }
            })
            .then(
                res => {
                    toast.success("Lead generated successfully.");
                    setTimeout(() => {
                        navigate(0); // Change "/new-page" to your target route
                      }, 2000);
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
        }
    }
    const [loading, setLoading] = useState(true);
    if (loading) return <Loader />;
    return(
        <div className="container">
            <ToastContainer />
            <h2>Add Manual Lead</h2>
            <br />
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-6">
                        <input type="text" placeholder="Enter Client Name" className="form-control" name="name" required/>
                    </div>
                    <div className="col-sm-6">
                        <input type="email" placeholder="Enter Email Here" className="form-control" name="email" required/>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-6">
                        <input type="text" placeholder="Enter Phone Number" className="form-control" name="phone" required/>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" placeholder="Business Name" className="form-control" name="business" required/>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-6">
                        <select className="form-control" name="country" required>
                            <option disabled selected>Select Country</option>
                            {countries
                            .filter(country => country.Status === "Active")
                            .map(country => (
                                <option value={country.Country}>{country.Country}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-6">
                        <button className="btn btn-primary">Generate Lead</button>
                    </div>
                </div>
            </form>
            <br />
            <h3>Or</h3>
            <br />
            <UploadCSV />
        </div>
    )
}