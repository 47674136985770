import React from "react";
import UploadEmailCSV from "../../components/UploadEmailCSV";

export default function ValidateEmails(){
    return(
        <div className="container">
            <h1 className="text-light">Email Validator</h1>
            <br />
            <UploadEmailCSV />
        </div>
    )
}