import React from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import AdminDashboard from "./pages/AdminDashboard";
import Cookies from "js-cookie";
import Login from "./pages/Login";
import ShowUsers from "./pages/ShowUsers";
import AddUser from "./pages/AddUser";
import DeleteUser from "./pages/DeleteUser";
import Countries from "./pages/Countries";
import AddCountry from "./pages/AddCountry";
import UpdateCountry from "./pages/UpdateCountry";
import DeleteCountry from "./pages/DeleteCountry";
import ShowLeads from "./pages/ShowLeads";
import UploadLeads from "./pages/UploadLeads";
import UpdateLead from "./pages/UpdateLead";
import DeleteLead from "./pages/DeleteLead";
import AllLeads from "./pages/AllLeads";
import UpdateProfile from "./pages/UpdateProfile";
import Emails from "./pages/emails/Emails";
import ValidateEmails from "./pages/emails/ValidateEmails";
import SendEmails from "./pages/emails/SendEmails";
import FollowUp from "./pages/FollowUp";
import AddFollowUp from "./pages/AddFollowUp";
import UpdateFollowUp from "./pages/UpdateFollowUp";
import DeleteFollowUp from "./pages/DeleteFollowUp";

function App() {
  const getUsername = Cookies.get("username");
  const getRole = Cookies.get("user_role");

  return (
    <div>
      {getUsername ?(
        <Routes>
          <Route path="/" element={<AdminDashboard />}></Route>
          <Route path="/leads/:country" element={<ShowLeads />}></Route>
          <Route path="/updateprofile" element={<UpdateProfile />}></Route>
          <Route path="/follow-up-sheet" element={<FollowUp />}></Route>
          <Route path="/add-follow-up" element={<AddFollowUp />}></Route>
          <Route path="/update-follow-up/:id" element={<UpdateFollowUp />}></Route>
          {getRole === "Administrator" &&
            <>
              <Route path="/users" element={<ShowUsers />}></Route>
              <Route path="/adduser" element={<AddUser />}></Route>
              <Route path="/deleteuser/:id" element={<DeleteUser />}></Route>
              <Route path="/countries" element={<Countries />}></Route>
              <Route path="/addcountry" element={<AddCountry />}></Route>
              <Route path="/updatecountry/:id" element={<UpdateCountry />}></Route>
              <Route path="/deletecountry/:id" element={<DeleteCountry />}></Route>
              <Route path="/uploadleads" element={<UploadLeads />}></Route>
              <Route path="/updatelead/:id" element={<UpdateLead />}></Route>
              <Route path="/deletelead/:id" element={<DeleteLead />}></Route>
              <Route path="/allleads" element={<AllLeads />}></Route>
              <Route path="/emails" element={<Emails />}></Route>
              <Route path="/emails/validate-emails" element={<ValidateEmails />}></Route>
              <Route path="/emails/email-sender" element={<SendEmails />}></Route>
              <Route path="/delete-follow-up/:id" element={<DeleteFollowUp />}></Route>
            </>
          }
        </Routes>
      ):(
        <Login />
      )
      }
    </div>
  );
}

export default App;
